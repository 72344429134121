// babel polyfills
import 'core-js/stable'

import SdkApi from './api'
import { inBrowser } from './utils/runtime'

declare global {
  interface Window {
    salsifyExperiencesSdk: SdkApi
    salsifyExperiencesSdkLoaded(salsify: SdkApi): void
  }
}

const sdk = new SdkApi('bundle')

if (!inBrowser()) {
  throw Error(
    "It looks like you're attempting to use the Salisfy Experiences SDK bundle outside of a browser environment. Please use the NPM module instead: https://www.npmjs.com/package/salsify-experiences-sdk"
  )
} else {
  window.salsifyExperiencesSdk = sdk
  if (window.salsifyExperiencesSdkLoaded) {
    window.salsifyExperiencesSdkLoaded(sdk)
  }
}
