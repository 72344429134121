/**
 * @module events
 */

import { Logger } from '../utils/logger'
import NavigationEventHandler, { NavigationEventOptions } from './navigation'
import AddToCartEventHandler, { AddToCartEventOptions } from './add-to-cart'
import EnhancedContentApi from '../enhancedContent'

/** @internal */
export interface EventsApiOptions {
  beforeNavigation?: (options?: NavigationEventOptions) => void
  onNavigation?: (options?: NavigationEventOptions) => void
}

/**
 * This class is responsible for handling events.
 *
 * It is responsible for exposing the public methods for triggering each different type of event.
 */
export default class EventsApi {
  #logger: Logger
  #options?: EventsApiOptions
  #ecApi?: EnhancedContentApi

  /** @internal */
  public constructor(logger: Logger, options?: EventsApiOptions, ecApi?: EnhancedContentApi) {
    this.#logger = logger
    this.#options = options
    this.#ecApi = ecApi
  }

  /**
   * Triggers the `navigation` event.
   *
   * @example
   * ```typescript
   * salsify.events.navigation({
   *   productIdType: "SKU",
   *   productId: "123",
   * });
   * ```
   *
   * @param options The options to pass to the event handler.
   */
  public navigation(options: NavigationEventOptions): void {
    this.#options?.beforeNavigation?.(options)
    new NavigationEventHandler(this.#logger).handle(options)
    this.#options?.onNavigation?.(options)
  }

  /**
   * Triggers the `addToCart` event.
   *
   * @example
   * ```javascript
   * const salsify = window.salsifyExperiencesSdk;
   * const options = { quantity: 1, productIdType, productId };
   * salsify.events.addToCart(options);
   * ```
   * @param options The options to pass to the event handler.
   */

  public addToCart(options: AddToCartEventOptions): void {
    new AddToCartEventHandler(this.#logger).handle(options, { lastEcRenderConfig: this.#ecApi?.lastRenderConfig })
  }
}
