import BaseEventHandler from '../base'
import { EventCallbackOptions } from '../base'

/**
 * The set of options that can be passed to the {@link "events".EventsApi.navigation | Navigation} event handler.
 */
export interface NavigationEventOptions extends EventCallbackOptions {
  /** @deprecated @internal Item price, without currency */
  price?: number
  /** @deprecated @internal Item currency as a 3-character alphabetic ISO-4217 code */
  currency?: string
  /** @deprecated @internal Item quantity */
  quantity?: number
}

/** @internal */
export default class NavigationEventHandler extends BaseEventHandler {
  public eventName = 'navigation'

  public handle(options: NavigationEventOptions): void {
    super.handle(options)
  }
}
