export enum Header {
  ContentLength = 'content-length',
}

const head = (url: string): Promise<Response> => fetch(url, { method: 'HEAD' })

const get = (url: string): Promise<Response> => fetch(url, { method: 'GET' })

const post = (url: string, data: Record<string, unknown>): Promise<Response> =>
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'content-type': 'text/plain',
    },
  })

export default { head, get, post }
