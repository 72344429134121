export function getCookie(key: string): string | undefined {
  const cookies = document.cookie.split('; ')

  for (const cookie of cookies) {
    const [k, v] = cookie.split('=')
    if (k === key) {
      return v
    }
  }
}

export function setCookie(
  key: string,
  value: string,
  expires = 'Mon, 01 Feb 2027 16:55:43 GMT',
  age = '315576000'
): void {
  const domain = window.location.hostname
  document.cookie = `${key}=${value};Path=/;Domain=${domain};Expires=${expires};Max-Age=${age}`
}

export function deleteCookie(key: string): void {
  if (getCookie(key)) {
    setCookie(key, '', 'Thu, 01 Jan 1970 00:00:00 UTC', '0')
  }
}
