import BaseEventHandler from '../base'
import { EventCallbackOptions } from '../base'
import { ErrorProperties } from '../../utils/logger'

/**
 * The set of options that can be passed to the {@link "events".EventsApi.addToCart | Add to Cart} event handler.
 */
export interface AddToCartEventOptions extends EventCallbackOptions {
  /** Item quantity */
  quantity: number
  /** Item price, without currency */
  price?: number
  /** Item currency as a 3-character alphabetic ISO-4217 code */
  currency?: string
}

const CURRENCY_REGEX = new RegExp(/^[A-Z]{3}$/)

/** @internal */
export default class AddToCartEventHandler extends BaseEventHandler {
  public eventName = 'add_to_cart'

  public handle(options: AddToCartEventOptions, extraProperties?: Record<string, unknown>): void {
    const errorMessage = this.#validateOptions(options)
    if (errorMessage) {
      this.handleValidationError(errorMessage)
    } else {
      super.handle(options, extraProperties)
    }
  }

  #validateOptions(options: AddToCartEventOptions): ErrorProperties['errorMessage'] | undefined {
    if (options.currency && !CURRENCY_REGEX.test(options.currency)) {
      return `Invalid 3-character alphabetic ISO-4217 currency code: ${options.currency}. See: https://www.iso.org/iso-4217-currency-codes.html.`
    }
    return undefined
  }
}
