function checkFlag(key: string): boolean {
  if (typeof localStorage === 'undefined') {
    return false
  }
  return localStorage?.[key]
}

export function useConsoleLogger(): boolean {
  return checkFlag('sxp_sdk_debug')
}
