import { Logger, ErrorProperties } from '../../utils/logger'

export interface EventCallbackOptions {
  /** Product identifier type that will be used to correlate published EC with products on your website
   *
   *  This is often a standardized identifier such as a UPC (GTIN-12) or EAN (GTIN-13), or your website’s product SKU
   */
  productIdType: string
  /** Product identifier */
  productId: string
  /** Brand name */
  brand?: string
  /** Product category hierarchy (from least specific to most specific)
   *
   * Example: ['Baby', 'Car Seats', 'Convertible Car Seats']
   */
  category?: string | Array<string>
}

const VALIDATION_ERROR_PROPS: Pick<ErrorProperties, 'errorType' | 'errorContext'> = {
  errorType: 'validation',
  errorContext: 'event',
}

/** @internal */
export default abstract class BaseEventHandler {
  #logger: Logger
  public abstract eventName: string
  public constructor(logger: Logger) {
    this.#logger = logger
  }

  public handle(options: EventCallbackOptions, extraProperties?: Record<string, unknown>): void {
    this.#logger.log(this.eventName, { ...options, ...extraProperties })
  }

  protected handleValidationError(errorMessage: ErrorProperties['errorMessage']): void {
    this.#logger.log('error', { ...VALIDATION_ERROR_PROPS, errorMessage })
    throw new Error(errorMessage)
  }
}
